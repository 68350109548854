<template>
  <AlertBox type="warning" :messages="responseError" />
  <SetLoader v-if="!calendarPageRequestFinish && !responseError.length" />
  <div v-else-if="validations.isOk">
    <InstantFilterModal
      :pageData="listData"
      v-if="
        listData && listData.filter && listData.filter.isInstantFilterActive
      "
    />
    <FullCalendar
      :self="this"
      :events="calendarEvents"
      :resources="calendarResources"
      :calendar="calendar"
      :fields="fields"
    />
    <CalendarHiddenFields :calendar="calendar" />
  </div>
  <CalendarValidation
    :viewFilterErrors="validations.viewFilterErrors"
    :calendarErrors="validations.calendarErrors"
    v-else-if="!validations.isOk"
  />
</template>
<script>
import { createToast } from "mosha-vue-toastify";
import InstantFilterModal from "@/components/custom/set-pages/InstantFilterModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import FullCalendar from "@/components/full-calendar/FullCalendar.vue";
import CalendarHiddenFields from "@/components/set-design/form-items/CalendarHiddenFields.vue";
import CalendarValidation from "@/views/crafted/errors/CalendarValidation.vue";
export default {
  name: "CALENDAR",
  props: ["key", "calendarId", "filterId"],
  data() {
    return {
      calendarEvents: [],
      responseError: [],
      calendar: {},
      listData: {},
      fields: [],
      calendarItems: [],
      calendarResources: [],
      calendarPageLayout: {},
      pageData: {},
      calendarDesignedItems: [],
      calendarPageRequestFinish: false,
      validations: { isOk: false, viewFilterErrors: [], calendarErrors: [] },
    };
  },
  components: {
    FullCalendar,
    CalendarHiddenFields,
    InstantFilterModal,
    CalendarValidation,
  },
  watch: {
    "$route.params.key"() {
      if (this.$route.name === "calendar" && this.calendarPageRequestFinish) {
        this.getCalendarPage();
      }
    },
    "$route.params.calendarId"() {
      if (this.$route.name === "calendar" && this.calendarPageRequestFinish) {
        this.getCalendarPage();
      }
    },
    "$route.query.filterId"() {
      if (this.$route.name === "calendar" && this.calendarPageRequestFinish) {
        this.getCalendarPage();
      }
    },
  },
  methods: {
    defaultProperties() {
      this.responseError = [];
      this.listData = {};
      this.fields = [];
      this.calendar = {};
      this.calendarItems = [];
      this.calendarEvents = [];
      this.pageData = {};
      this.calendarDesignedItems = [];
      this.calendarPageRequestFinish = false;
    },
    validationForView() {
      var viewFilterErrors = [],
        calendarErrors = [];

      if (!String.isNullOrWhiteSpace(this.calendar.timelineFieldPublicId)) {
        var timelineField = this.fields.find(
          (f) => f.publicId == this.calendar.timelineFieldPublicId
        );
        if (!timelineField) {
          viewFilterErrors.push({
            publicId: this.calendar.timelineFieldPublicId,
            name: this.calendar.timelineFieldName,
          });
        }
      }

      if (!String.isNullOrWhiteSpace(this.calendar.startDateFieldPublicId)) {
        var startDateField = this.fields.find(
          (f) => f.publicId == this.calendar.startDateFieldPublicId
        );
        if (!startDateField) {
          viewFilterErrors.push({
            publicId: this.calendar.startDateFieldPublicId,
            name: this.calendar.startDateFieldName,
          });
        }
      }

      if (!String.isNullOrWhiteSpace(this.calendar.endDateFieldPublicId)) {
        var endDateField = this.fields.find(
          (f) => f.publicId == this.calendar.endDateFieldPublicId
        );
        if (!endDateField) {
          viewFilterErrors.push({
            publicId: this.calendar.endDateFieldPublicId,
            name: this.calendar.endDateFieldName,
          });
        }
      }

      if (!String.isNullOrWhiteSpace(this.calendar.titleFieldPublicIds)) {
        this.calendar.titleFieldPublicIds
          .split(this.$systemSeparator)
          .forEach((titleFieldPublicId, i) => {
            var titleField = this.fields.find(
              (f) => f.publicId == titleFieldPublicId
            );
            if (!titleField) {
              viewFilterErrors.push({
                publicId: titleFieldPublicId,
                name: this.calendar.titleFieldNames.split(
                  this.$systemSeparator
                )[i],
              });
            }
          });
      }

      if (this.$store.state.isMultiLanguage) {
        viewFilterErrors.forEach((validation) => {
          var fieldLocalizationValue =
            this.$store.getters.getLocalizationValuesByParameters({
              parentId: this.calendar.customObjectPublicId,
              itemPublicId: validation.publicId,
              itemTypeId: 2,
            });
          if (fieldLocalizationValue) {
            validation.name = fieldLocalizationValue.value;
          }
        });
      }

      if (
        !String.isNullOrWhiteSpace(this.calendar.views) &&
        this.calendar.views.includes("resource")
      ) {
        if (
          timelineField &&
          timelineField.fieldModel &&
          timelineField.fieldModel.inputHtmlType !== "select"
        ) {
          calendarErrors.push({
            publicId: this.calendar.timelineFieldPublicId,
            name: this.calendar.timelineFieldName,
            message: this.$t(
              "Components.FullCalendar.ResourceOnlySelectFieldError",
              {},
              { locale: this.$store.state.activeLang }
            ),
            views: this.calendar.views
              .split(this.$systemSeparator)
              .filter((f) => f.includes("resource")),
          });
        }
      }

      return {
        viewFilterErrors: Array.uniqueByParameter(
          [...viewFilterErrors],
          "publicId"
        ),
        calendarErrors: calendarErrors,
        isOk: viewFilterErrors.length === 0 && calendarErrors.length === 0,
      };
    },
    getCalendarEvents(key, filterId = "", dataFetchSize = 500) {
      this.$appAxios
        .get(
          `rws-SetController-List?key=${key}&filterId=${
            String.isNullOrWhiteSpace(filterId) ? "" : filterId
          }&page=1&size=${dataFetchSize}&orderFields=&ordering=&isAsc=false`
        )
        .then((response) => {
          var result = response.data;
          this.listData = result;
          if (
            result.isOk &&
            result.msg === this.$viewFilterCriteriaRequiredErrorKey
          ) {
            createToast(
              this.$t(
                "Components.FullCalendar.InstantRecordListingError",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: true,
                position: "top-right",
                type: "danger",
                transition: "zoom",
              }
            );
            this.$router.push({
              name: "list",
              params: { key: this.listData.customObject.key },
              query: {
                filterId: this.listData.filter.publicId,
              },
            });
            return;
          }

          if (this.$store.state.isMultiLanguage) {
            var localizationValue =
              this.$store.getters.getLocalizationValuesByParameters({
                parentId: null,
                itemPublicId: this.calendar.publicId,
              });
            if (localizationValue) {
              this.calendar.name = localizationValue.value;
            }

            var localizationValueForPluralName =
              this.$store.getters.getLocalizationValuesByParameters({
                itemFormulaName: this.listData.customObject.formulaName,
                itemTypeId: 11,
              });
            if (localizationValueForPluralName) {
              this.listData.customObject.pluralName =
                localizationValueForPluralName.value;
            }
          }

          var filteredListData = Object.filteredJSONByKeys(this.listData, [
            "pagedItems",
          ]);

          this.calendarPageRequestFinish = true;
          this.$store.commit("setPageDto", {
            ...filteredListData,
            pageDetail: this.calendar,
          });

          if (result.isOk) {
            this.$store.commit("setPageLayoutAvailable", true);
            this.fields = result.filter.visibleFields;
            if (result.filter.hiddenFields.length) {
              result.filter.hiddenFields.forEach((element) => {
                this.fields.push(element);
              });
            }

            this.validations = this.validationForView();
            if (this.validations.isOk) {
              this.calendarItems = result.pagedItems.items;
              this.calendarEvents = this.$root.calendarDataChange(
                result.pagedItems.items,
                this.calendar,
                this.fields
              );
              this.calendarResources = this.$root.calendarDataChange(
                result.pagedItems.items,
                this.calendar,
                this.fields,
                true
              );
            }
          } else {
            this.responseError.push(result.msg);
          }

          var title = this.$t(
            "Set.CalendarFormat",
            {},
            { locale: this.$store.state.activeLang }
          ).replaceAll("OBJECT_NAME", this.calendar.name);
          setCurrentPageBreadcrumbs(title, [
            {
              name: this.listData.customObject.pluralName,
              isRouteLink: true,
              html: `/set/list/${this.$route.params.key}`,
            },
            {
              name: title,
            },
          ]);

          this.$root.changeDocumentTitle(title);
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    getCalendarPage() {
      setCurrentPageBreadcrumbs("", [{ name: "HELP" }]);
      this.defaultProperties();
      this.$root.getSetScripts(this.$route.params.key, "Calendar").then(() => {
        this.pageProcess();
      });
    },
    pageProcess() {
      var key = this.$route.params.key,
        calendarId = this.$route.params.calendarId,
        filterId = this.$route.query.filterId;

      if (
        String.isNullOrWhiteSpace(key) ||
        String.isNullOrWhiteSpace(calendarId)
      ) {
        this.$router.push(this.$appHomeUrl);
        return;
      }

      this.$appAxios
        .get(`rws-GetCalendarView?publicId=${calendarId}`)
        .then((response) => {
          this.calendar = response.data;
          if (!this.calendar.isOk) {
            this.$router.push({
              name: "PermissionNone",
              params: { key: key },
            });
          } else {
            this.$root.operationAfterPageLoadForSetScripts();
            this.getCalendarEvents(key, filterId, this.calendar.dataFetchSize);
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
  },
  mounted() {
    this.getCalendarPage();
  },
};
</script>
